/* styles for GoDeeperItem */

.gdc-popup {
    position: fixed; /* Stay in place */
    z-index: 2000; /* Sit on top of menu, which is 1000*/
    top: 10vh;
    left:50%;
    transform: translateX(-50%);
    
    width: 80vw; /* not full width */
    height: 90vh; /* not full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
    border-radius: 30px;
    border-width: 0;
}

.gdc-scrolling-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.gdc-card {
    box-sizing: border-box;
    flex: 0 0 auto;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: justify;
    overflow-y: auto;
    max-width: 80vw;
    height: 90vh;
    margin: 0px;
    margin-top: 20px;
    scroll-snap-stop: always;
    scroll-snap-align: center;
    padding: 10px;
}

.gdc-card-image-wrapper {
    display: flex;
    align-items: center;
    max-height: 50vh;
    max-width: 90%;
    border-radius: 20px;
}

.gdc-card-image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

.gdc-card-id {
    font-size: 1.3em;
    font-weight: bold;
    color: var(--yellow);
}

.gdc-card p {
    margin-top: 20px;
    margin-bottom: 20px;
}

.gdc-card p a {
    color: var(--yellow);
    text-decoration: none;
}

.gdc-video-container-mux {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.gdc-card mux-player {
    --seek-backward-button: none;
    --seek-forward-button: none;
    border-radius: 20px;
    object-fit: contain;
}

/* The Close Button */
.gdc-close {
    position:absolute;
    top:10px;
    right:10px;
    color: #aaa;
    font-size: 2em;
    font-weight: bold;
  }
  
.gdc-arrow-button {
    display: inline-block;
    vertical-align: middle;
}

.gdc-left-button {
    position:absolute;
    top:50vh;
    left:10px;
}

.gdc-left-arrow {
    display: inline-block;
    width: 4em;
    height: 4em;
    border: 0.4em solid #FFFFFF;
    border-radius: 50%;
    margin-right: 1.5em;
}

.gdc-left-arrow:after {
    content: '';
    display: inline-block;
    margin-top: .8em;
    margin-left: 1.1em;
    width: 1.2em;
    height: 1.2em;
    border-top: 0.4em solid #FFFFFF;
    border-right: 0.4em solid #FFFFFF;
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

.gdc-right-button {
    position:absolute;
    top:50vh;
    right:10px;
}

.gdc-right-arrow {
    display: inline-block;
    width: 4em;
    height: 4em;
    border: 0.4em solid #FFFFFF;
    border-radius: 50%;
    margin-left: 1.5em;
}

.gdc-right-arrow:after {
    content: '';
    display: inline-block;
    margin-top: .8em;
    margin-left: 0.6em;
    width: 1.2em;
    height: 1.2em;
    border-top: 0.4em solid #FFFFFF;
    border-right: 0.4em solid #FFFFFF;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.gdc-close:hover, .gdc-close:focus,
.gdc-left-button:hover, .gdc-left-button:focus,
.gdc-right-button:hover, .gdc-right-button:focus {
color: var(--yellow);
text-decoration: none;
cursor: pointer;
}
.gdc-left-arrow:hover, .gdc-left-arrow:focus,
.gdc-right-arrow:hover, .gdc-right-arrow:focus{
    border: 0.4em solid var(--yellow);
}

.gdc-left-arrow:hover:after,
.gdc-right-arrow:hover:after {
    border-top: 0.4em solid var(--yellow);
    border-right: 0.4em solid var(--yellow);
}

.gd-slideshow-card-title {
    font-style: italic;
}

.gdc-caption-text {
    flex-grow: 1;
    font-family: "Futura", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--white);
    padding: 20px;
    max-width: 80%;
    margin: auto;
}


.gd-no-bullet-list {
    list-style-type: none; /* Remove bullets */
}

/* Desktop styles */
@media (min-width: 1200px) {
    .gdc-card mux-player, .gdc-card video {
        max-height: 50vh;
        max-width: 80%;
    }
}

/* Tablet styles */
@media (min-width: 760px) and (max-width: 1199px) {
    .gdc-popup {
        /*z-index: 2000; /* Sit on top of menu, which is 1000*/
        top:0;
        width: 90vw;
        height: 90vh;
        padding: 10px;
        border-radius: 10px;
    }

    .gdc-card {
        max-width: 90vw;
        height: 85vh;
    }

    .gdc-card mux-player, .gdc-card video {
        max-height: 50vh;
        max-width: 80%;
    }

    .gdc-left-button {
        top:55vh;
        left:10px;
    }
    
    .gdc-left-arrow {
        width: 3em;
        height: 3em;
        border: 0.4em solid #FFFFFF;
    }
    
    .gdc-left-arrow:after {
        margin-top: .5em;
        margin-left: 0.7em;
        width: 0.8em;
        height: 0.8em;
        border-top: 0.4em solid #FFFFFF;
        border-right: 0.4em solid #FFFFFF;
      }
    
    .gdc-right-button {
        top:55vh;
        right:10px;
    }
    
    .gdc-right-arrow {
        width: 3em;
        height: 3em;
        border: 0.4em solid #FFFFFF;
    }
    
    .gdc-right-arrow:after {
        margin-top: .5em;
        margin-left: 0.4em;
        width: 0.8em;
        height: 0.8em;
        border-top: 0.4em solid #FFFFFF;
        border-right: 0.4em solid #FFFFFF;
    }
    
}


/* mobile styles */
@media (max-width: 767px) {
    .gdc-popup {
        z-index: 2000; /* Sit on top of menu, which is 1000*/
        top:0;
        width: 100vw; /* Full width */
        height: 100vh;
        padding: 10px;
        border-radius: 10px;
    }

    .gdc-card {
        max-width: 100vw;
        height: 100vh;
    }

    .gdc-card-image-wrapper {
        max-height: 80vh;
        max-width: 100%;
        object-fit: contain;
        border-radius: 10px;
    }

    .gdc-card mux-player, .gdc-card video {
        max-width: 100vw;
        max-height: 80vw;
    }

    .gdc-caption-text {
        max-width: 100%;
        font-size: 1.0rem;
    }

    .gdc-left-button {
        z-index:2000;
        top:38vh;
        left:10px;
    }
    
    .gdc-left-arrow {
        width: 2em;
        height: 2em;
        border: 0.3em solid #FFFFFF;
    }
    
    .gdc-left-arrow:after {
        margin-top: .3em;
        margin-left: 0.4em;
        width: 0.5em;
        height: 0.5em;
        border-top: 0.3em solid #FFFFFF;
        border-right: 0.3em solid #FFFFFF;
      }
    
    .gdc-right-button {
        z-index:2000;
        top:38vh;
        right:10px;
    }
    
    .gdc-right-arrow {
        width: 2em;
        height: 2em;
        border: 0.3em solid #FFFFFF;
    }
    
    .gdc-right-arrow:after {
        margin-top: .3em;
        margin-left: 0.25em;
        width: 0.5em;
        height: 0.5em;
        border-top: 0.3em solid #FFFFFF;
        border-right: 0.3em solid #FFFFFF;
    }
    
    .gdc-left-arrow:hover, .gdc-left-arrow:focus,
    .gdc-right-arrow:hover, .gdc-right-arrow:focus{
        border: 0.3em solid var(--yellow);
    }
    
    .gdc-left-arrow:hover:after,
    .gdc-right-arrow:hover:after {
        border-top: 0.3m solid var(--yellow);
        border-right: 0.3em solid var(--yellow);
    }
}