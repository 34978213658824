/* Navbar.css */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --header-height: 3.5rem;
  --z-tooltip: 10;
  --z-fixed: 100;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: Futura, sans-serif;
}

ul {
  list-style: none;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0%;
  background-color: transparent;
  
  z-index: 1000;
  padding: 1rem;
}

.blue-background {
  background-color: var(--blue);
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__toggle {
  cursor: pointer;
}

.nav__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 38%;
}

.nav__link {
  color: var(--gray);
  font-weight: Bold;
  font-family: Futura, sans-serif;
  transition: color 0.4s;
}

.nav__link:hover {
  color: var(--yellow);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.mobile-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.mobile-navbar li {
  margin-right: 20px;
  display: inline-block;
}

.mobile-navbar li:last-child {
  margin-right: 0;
}

.navbar-right{
  float: right;
}

.navbar-center{
  width: 25%;
  margin: 0 auto;
}

.navbar ul {
  list-style: none;
  margin: 0;
  margin-top: 2vh;
  padding: 0;
  display: flex; 
  justify-content: space-between;
  margin-right: 2vw;
  color: var(--white);
  font-size: 1.5vw;
  font-weight: Bold;
  font-family: Futura, sans-serif;
}

.navbar li:first-child {
 margin-right: auto; 
 padding-left: 2vw;
}

.navbar li:not(:first-child) {
  margin-left: 2vw;
}

.navbar-item{
  color: var(--white);
  font-size: 1.5vw;
  font-weight: Bold;
  font-family: Futura, sans-serif;
  text-decoration: none;
}

.navbar-item:hover{
  color: var(--yellow);
  cursor: pointer;
}