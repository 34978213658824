.nav-panel {
    position: fixed;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    z-index: 1000;
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.57, 1.2, 0.68, 2.6);
    will-change: transform, opacity;
}

.nav-panel ul {
  list-style-type: none;
}

.nav-btn {
  position: relative;
  overflow: hidden;
  width: 1em;
  height: 1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.circle {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    margin-top: 1vh;
    background: var(--blue);
    border: 3px solid var(--white);
    cursor: pointer;
  }

.circle-blank {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    margin-top: 1vh;
    border: 2px dotted var(--grey);
    cursor: default;
}

.square {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 20%;
    transform: rotate(45deg);
    margin-top: 1vh;
    background: var(--white);
    cursor: pointer;
}

.intro-color{
    background: var(--pink);
}

.ep1-color{
    background: var(--orange);
}

.ep2-color{
  background: var(--purple);
}
  
  /* Mobile styles */
  @media (max-width: 767px) {
    .circle {
      width: 3vw;
      height: 3vw;
      border-radius: 50%;
      margin-top: 1vh;
      border: 1px solid var(--white);
      cursor: pointer;
    }
  
  .circle-blank {
      width: 3vw;
      height: 3vw;
      border-radius: 50%;
      margin-top: 1vh;
      border: 1px dotted var(--grey);
      cursor: default;
  }

  .square {
    width: 3vw;
    height: 3vw;
    border-radius: 20%;
    transform: rotate(45deg);
    margin-top: 1vh;
    background: var(--white);
    cursor: pointer;
  }
}