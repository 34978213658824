
/* Import the montserrat PT font family from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/*---------------Color--------------*/
/*listed at app.css (--blue, --yellow, --orange, --grey, --pink, --white)

/*Styles*/
/*----------------------------------*/
html, body {
  margin: 0;
  background-color: var(--blue);
  font-family: Futura, sans-serif;
}

.scroll-snap {
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
}

.scroll-snap::-webkit-scrollbar {
display: none;
}

.parent {
    display: grid; 
    grid-template-columns: 0.9fr 1.1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "topDiv topDiv topDiv"
      "middleDiv middleDiv middleDiv"
      "bottomDiv bottomDiv bottomDiv"; 
  }

.top-div { grid-area: topDiv; width: fit-content;}
.middle-div { grid-area: middleDiv; padding-top: 12%;}
.bottom-div { grid-area: bottomDiv; }

.crab {
    position: absolute;
    z-index: 10;
    width: calc(max(25vw, 25vh));
    top: -35vh;
    left: 5vw;
}

.play-trigger {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%!important;
    width: 100%!important;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    border-radius: 2vw;
    cursor: pointer;
}



/*------------Text style------------*/
/*----------------------------------*/
.text-width-center {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

.text-width-left{
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
}

.text-italic {
    font-style: italic;
}

.text-yellow {
    color: var(--yellow);
}

.chapter-number-text {
    font-family: "Futura", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: var(--white); 
    font-size: calc(32px + 1vw);
}

.chapter-title-text {
    color: var(--yellow);
    font-size: calc(24px + 1vh);
    margin-top: -2vh;
}   

.chapter-subtext {
    font-family: "Futura", sans-serif;
    font-weight: 300;
    color: var(--white);
}

.next-number-text{
    font-family: "Futura", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: var(--white); 
    font-size: calc(32px + 1vw);
    padding: calc(20px + 1vw);
}

.next-title-text {
    font-style: Bold;
    color: var(--yellow);
    font-size: calc(56px + 1vw);
    margin-top: -4vh;
}

.next-release-text{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: var(--white); 
    font-size: calc(16px + 1vw);
    padding: calc(25px + 1vw);
}

.home-title-text {
    color: var(--yellow);
    font-family: "Futura", sans-serif;
    font-weight: bold;
}

.home-subtext {
    color: var(--white);
    font-family: "Futura", sans-serif;
    font-weight: 400;
}

/*----------Global Element----------*/
/*----------------------------------*/

.button {
  background-color: transparent;
  border: 2px solid var(--yellow);
  border-radius: 40px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: var(--yellow);
  margin: 10px;
  padding: 10px 30px;
  outline: none;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
}

.button:hover {
  background-color: var(--yellow);
  border-color: var(--yellow);
  transform: scale(.96);
  color: var(--blue);
}

.button:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}

.chapters-wrapper {
  width: 100%;
  height: 100vh;
  justify-content: center;
  scroll-snap-align: start;
  position: flexible;
  display:flex;
}

.chapter-0{
  background-color: var(--pink);
  z-index: 5;  
  width: 80%; 
  height: 100vh; 
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-sizing: border-box;
}

.upNextBlock{
    width: 80%; 
    height: 100vh; 
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    box-sizing: border-box;
}

.chapter-1{
    background-color: var(--orange);
    z-index: 3;
}

.chapter-2{
    background-color: var(--purple);
    z-index: 3;
}

.credit{
    background-color: var(--blue);
    width: 100%; 
    height: 100vh; 
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 3;
  }

.credit-title{
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: var(--white);
}

.credit-detail{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: var(--white);
}

.about-subtext {
    font-weight: 400;
    margin-left: 10vw;
    margin-right:10vw;
    text-align: justify;
}

iframe {
    border-radius: 2vw;
}

.video-container-mux {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.video-container-mux mux-player {
    --seek-backward-button: none;
    --seek-forward-button: none;
    border-radius: 2vw;
    overflow: hidden;
}

.video-container-mux mux-player::part(center play button) {
    background-color: rgba(255,255,255,0.3); /* White w/ opacity */
    border-radius: 50%;
    border: solid 2px var(--yellow);
  }

  /* Desktop styles */
@media (min-width: 1200px) {
    .chapter-number-text {
        font-size: 3vw;
        padding: 10vh;
    }
    
    .chapter-title-text {
        font-size: 4vw;
        margin-top: -10vh;
    }   
    
    .chapter-subtext {
        font-weight: 300;
        color: var(--white);
        font-size: 1.5rem;
    }

    .next-number-text{
        font-size: 3vw;
        padding: calc(20px + 1vw);
    }
    
    .next-title-text {
        font-size: 5vw;
        margin-top: -4vh;
    }
    
    .next-release-text{
        font-size: calc(16px + 1vw);
        margin-bottom: -3vh;
    }

    .button {
      border: 0.3vh solid var(--yellow);
      border-radius: 50px;
      font-size: 3vh;
      margin: 1vh;
      padding: 2vh 2vh;
      min-width: 30vh; /* set min-width to the same value */
      min-width: 30vh; /* set min-width to the same value */
    }
    
    .home-title-text {
        margin-top: 15%;
        font-size: 8vw;
    }
    
    .home-subtext {
        font-size: calc(16px + 1vw);
        font-weight: 600;
    }
    
    .subtext-light {
        font-weight: 300;
        margin-left: 10vw;
        margin-right:10vw;
        text-align: center;
        font-Size: 0.6em;
    }

    .chapter-0{
      width: 80%; 
      border-radius: 50px;
    }
    
    .upNextBlock{
        width: 80%; 
        height: 100vh; 
        border-radius: 50px;
    }

    .credit-title{
        font-size: 2vw;
    }

    .credit-detail{
        font-size: 2vw;
    }

    .chapter-1 .chapter-title-text {
        font-size: 5vw;
    }
    
    .chapter-2 .chapter-title-text {
        font-size: 5vw;
    }

    .video-text{
        margin-bottom: 2vh;
        margin-top: 2vh;
        margin-left: 10%;
        margin-right: 10%;
        text-align: justify;
        font-size: 1rem;
    }

    .video-container {
        position: relative;
        padding-bottom: 39.3vh; 
        height: 0;
        margin: 0 auto;
        text-align: center;
        overflow: hidden;
        max-width: 70vh; 
    }
      
    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .crab {
        width: calc(max(15vw, 15vh));
        top: -37%;
        left: 5vw;
    }

    .video-container-mux {
        max-width: 70vh; 
    }

    mux-player::part(center play button) {
        width: 70px;
        height: 70px;
        padding: 13px 13px 13px 13px;
    } 
}
  
  /* Tablet styles */
  @media (min-width: 760px) and (max-width: 1199px) {
    .chapter-number-text {
        font-size: 4vw;
        margin-top: 10vh;
    }
    
    .chapter-title-text {
        font-size: 6vw;
        margin-top: 0vh;
    }   
    
    .chapter-subtext {
        font-weight: 300;
        color: var(--white);
    }
    
    .next-number-text{
        font-size: calc(32px + 1vw);
        padding: calc(20px + 1vw);
    }
    
    .next-title-text {
        font-size: calc(56px + 1vw);
        margin-top: -4vh;
    }
    
    .next-release-text{
        font-size: calc(16px + 1vw);
        margin-bottom: -4vh;
    }
    
    .button {
      border: 2px solid var(--yellow);
      border-radius: 40px;
      font-size: 2vw;
      margin: 1vh;
      padding: 1vh 1vh;
      min-width: 250px; /* set min-width to the same value */
      max-width: 250px; 
    }
    
    .home-title-text {
        margin-top: 20%;
        font-size: calc(48px + 2vw);
    }
    
    .home-subtext {
        font-size: calc(16px + 1vw);
    }

    .subtext-light {
        font-weight: 300;
        font-Size: 0.8em;
    }

    .chapter-subtext {
        position: flex;
        font-weight: 300;
        color: var(--white);
        /* font-size: 1.5rem; */
        font-size: min(3vh, 3vw);    
    }
      
    .video-text{
        margin-bottom: 2vh;
        margin-top: 4vh;
        margin-left: 10%;
        margin-right: 10%;
        text-align: justify;
        font-size: 0.7em;
    }

    .chapter-0{
      width: 80%; 
      border-radius: 50px;
    }

    .chapter-1 .chapter-title-text {
        font-size: 4vw;
        margin-bottom: 3vh;
    }

    .chapter-1 .chapter-subtext {
        font-size: max(2vh, 2vw);    
    }
    
    .upNextBlock{
        width: 80%; 
        height: 100vh; 
        border-radius: 50px;
    }

    .credit-title{
        font-size: 2vw;
    }

    .credit-detail{
        font-size: 2vw;
    }

    .video-container {
        position: relative;
        padding-bottom: 28vh; 
        height: 0;
        margin: 0 auto;
        text-align: center;
        overflow: hidden;
        max-width: 50vh; 
    }
      
    .video-container .video-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .button {
        font-size: 1.5vw;
        min-width: 30vw; 
        max-width: 30vw; 
      }

    .crab {
        width: calc(max(15vw, 15vh));
        top: -30vh;
        left: 5vw;
    }

    .video-container-mux {
        max-width: 50vh; 
    }

    mux-player::part(center play button) {
        width: 50px;
        height: 50px;
        padding: 7px 8px 7px 9px;
    } 

  }
  
  /* Mobile styles */
  @media (max-width: 767px) {
    .chapter-number-text {
        font-size: 6vw;
        margin-top: 13vh;
        text-align: left;
    }
    
    .chapter-title-text {
        font-size:  10vw;
        margin-top: -1vh;
        text-align: left;
        margin-bottom: -5vh;
    }   
    
    .chapter-subtext {
        font-weight: 300;
        margin-top: 5vh;
        color: var(--white);
        font-size: 2vh;
    }
    
    .next-number-text{
        font-size: 4vh;
        margin-top: 20%;
    }
    
    .next-title-text {
        font-size: 5vh;
        margin-top: -4vh;
    }
    
    .next-release-text{
        font-size: 2.8vh;
        margin-top: 3vh;
        margin-bottom: -6vh;
        margin-left: -2vh;
        margin-right: -2vh;
    }
    
    .button {
      border: 2px solid var(--yellow);
      border-radius: 20px;
      font-size: 4.5vw;
      margin: 1vh;
      padding: 1vh 1vh;
      min-width: 200px; 
      max-width: 200px; 
    }
    
    .home-title-text {
        margin-top: 35%;
        font-size: 13vw;
    }
    
    .home-subtext {
        font-size: calc(12px + 1vw);
        font-weight: 800;
    }

    .subtext-light {
        font-weight: 300;
        font-Size: 0.8em;
    }
    
    .chapter-0{
      width: 80%; 
    }
    
    .upNextBlock{
        height: 100vh; 
    }

    .video-text{
        margin-bottom: 1vh;
        margin-top: 2vh;
        text-align: left;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%; 
        height: 100%;
        overflow: hidden;
        max-width: 100%;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-container-mux {
        max-width: 100%;
    }

    mux-player::part(center play button) {
        width: 30px;
        height: 30px;
        padding: 5px 5px 5px 7px;
    } 

    .chapter-1 .chapter-title-text {
        font-size: 5vw;
        margin-bottom: -3vh;
    }

    .chapter-1 .chapter-subtext {
        font-size: 1.9vh;
    }
  }