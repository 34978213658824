/* Styles for the Go Deeper page */

.gd-title-text {
    color: var(--yellow);
    font-family: "Futura", sans-serif;
    font-weight: bold;
}

.gd-middle-div {
    grid-area: middleDiv;
    padding-top: 12%;
}

.go-deeper-section {
    font-weight: 400;
    max-width: 100vw;
    padding: 20px 40px 10px 40px;
    margin-left: 10vw;
    margin-right:10vw;
    text-align: justify;

    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    font-size: 0.8em;
}

.go-deeper-section p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 400;
    color: var(--white);
}

.go-deeper-section a:link, .go-deeper-section a:visited {
    color: var(--yellow);
    text-decoration: none;
}

.go-deeper-section a:hover {
    text-decoration: underline;
}

.gd-ep0 {
    background-color: var(--pink);    
}

.gd-ep1 {
    background-color: var(--orange);    
}

.gd-section-title {
    font-weight: 600;
    color: var(--white);
}


.gd-sub-section-title {
    padding-top: 20px;
    clear:both;
    font-style: italic;
}

.gd-abc-list {
    margin-left: 50px;
    margin-bottom: 20px;
}

.gd-abc-list li::marker {
    content: counter(list-item, upper-alpha)") ";
}

.gd-episode-list-item-container {
    margin-top: 30px;
}

.gd-episode-cover-img {
    border-radius: 8px;
    max-width:30vw;
    margin-right: 20px;
}

.gd-episode-title {
    font-size: 2rem;
    align-items: center;
}

.gd-episode-cover-img, .gd-episode-title {
    display: inline;
    vertical-align: middle;
}

.gd-preamble {
    font-size: 1.2rem;
}

.gd-img-container {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 20px;
    column-gap: 20px;
}

.gd-return-to-episode-container {
    display: flex;
    justify-content: center;
}

.gd-button {
    white-space: nowrap;
    background-color: transparent;
    border: 2px solid var(--yellow);
    border-radius: 40px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: var(--yellow);
    margin: 10px;
    padding: 10px 30px;
    outline: none;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
}

.gd-button:hover {
    background-color: var(--yellow);
    border-color: var(--yellow);
    transform: scale(.96);
    color: var(--blue);
}
  
.gd-button:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
}

.gd-slideshow-section {
    margin-top: 40px;
    margin-bottom: 20px;
}

.gd-slideshow-section-title {
    font-weight: bold;
    color: var(--yellow);
}

.gd-img-preview-container {
    position: relative;
}

.gd-img-preview-container:hover,
.gd-img-preview-container:focus {
  cursor: pointer;
}

.gd-text-overlay {
    position:absolute;
    top: 10px;
    left: 10px;
    color: var(--yellow);
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
    border-radius: 8px;
    padding: 5px;
}

.gd-img-preview-id {
    display: inline-block;
    padding-right: 10px;
    font-size: 2em;
}

.gd-img-preview-blurb {
    display: inline-block;
    font-size: smaller;
}

/* Desktop styles */
@media (min-width: 1200px) {
    .gd-img-preview {
        max-width: 36vw;
        max-height: 500px;
        border-radius: 8px;
    }

    .gd-title-text {
        font-size: 8vw;
    }

    .gd-button {
        border: 0.3vh solid var(--yellow);
        border-radius: 50px;
        font-size: 3vh;
        margin: 1vh;
        padding: 2vh 2vh;
        min-width: 30vw;
    }
}


/* Tablet styles */
@media (min-width: 760px) and (max-width: 1199px) {
    .go-deeper-section {
        margin-left: 5vw;
        margin-right: 5vw;
        padding: 20px 40px 10px 40px;
        border-radius: 20px;
    }

    .gd-title-text {
        margin-top: 15vw;
        font-size: calc(48px + 2vw);
    }

    .gd-episode-title {
        font-size: 1.6rem;
    }

    .gd-img-preview {
        max-width: 40vw;
        max-height: 500px;
        border-radius: 8px;
    }

    .gd-button {
        border: 2px solid var(--yellow);
        border-radius: 40px;
        font-size: 1.5vw;
        margin: 1vh;
        padding: 1vh 1vh;
        min-width: 30vw; 
      }
}

/* mobile styles */
@media (max-width: 767px) {
    .gd-title-text {
        margin-top: 15vh;
        font-size: 13vw;
    }

    .gd-episode-title {
        font-size: .8rem;
    }

    .gd-figure-container {
        max-width: 100vw;
    }

    .go-deeper-section {
        margin-left: 20px;
        margin-right: 20px;
        padding: 20px 10px 10px 10px;
        border-radius: 10px;
        text-align: justify;
        font-size: 0.8em;
    }

    .gd-img-container {
        margin-top:20px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .gd-img-preview {
        width: 100%;
        border-radius: 8px;
    }

    .gd-button {
        border: 2px solid var(--yellow);
        border-radius: 20px;
        font-size: 4.5vw;
        margin: 1vh;
        padding: 1vh 1vh;
        min-width: 200px; 
    }
    
}
